import Ridaali from "../images/Ridaali.png";
import portfolio from "../images/Image.png";
import Project1 from "../images/project1.png";
import Project2 from "../images/project2.png";
import Project3 from "../images/project3.png";
import landscape from "../images/landscape-1.jpg";
import landscape1 from "../images/landscape-2.jpg";
import logo from "../images/logo-rida.png";

const Images = {
    
    Ridaali,
    portfolio,
    Project1,
    Project2,
    Project3,
    landscape,
    landscape1,
    logo
}


export default Images;