import React from "react";
import { Header } from "../../common";
import Images from "../../constant/Images";
import { Helmet } from "react-helmet";
import "./Resume.css";

export default function Resume() {
  return (
    <>
      <Helmet>
        <title>
          Rida Ali | Resume
        </title>
        <meta
          name="description"
          content="Rida Ali, Interior Architect,Resume"
        />
        <meta
          name="keywords"
          content="Rida Ali Resume"
        />
      </Helmet>
      <div className="row mx-0 px-0 justify-content-center">
        <div className="col-5 d-none d-md-block text-start pt-5 ">
          <img
            src={Images.Ridaali}
            className=" img-fluid  logo-rida "
            alt="Rida Ali"
          />
        </div>
        <div className="col-12 col-md-5 ">
          <Header isPage />
        </div>
      </div>

      <div className="row mx-0 px-0 py-5 justify-content-center">
        <div className="col-md-10 ">
          <h2 className="text-start about-title py-5">RESUME</h2>
          <p className="about-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            vulputate tristique quam felis. Id phasellus dui orci vulputate
            consequat nulla proin. Id sit scelerisque neque, proin bibendum
            diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            vulputate tristique quam felis. Id phasellus dui orci vulputate
            consequat nulla proin. Id sit scelerisque neque, proin bibendum
            diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            vulputate tristique quam felis. Id phasellus dui orci vulputate
            consequat nulla proin.{" "}
          </p>
          <div className="row ">
            <div className="col-lg-4"></div>
            <div className=" col-lg-8 text-start mx-0 ps-4 px-lg-0">
              <h4 className="pt-5 resume-year">2018-2024</h4>
              <p className="pt-3 about-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                vulputate tristique quam felis. Id phasellus dui orci vulputate
                consequat nulla proin. Id sit scelerisque neque, proin bibendum
                diam.
              </p>
              <h4 className="pt-5 resume-year">2014-2018</h4>
              <p className="pt-3 about-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                vulputate tristique quam felis. Id phasellus dui orci vulputate
                consequat nulla proin. Id sit scelerisque neque, proin bibendum
                diam.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
