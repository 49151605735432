import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Images from "../../constant/Images";
import { Link } from "react-router-dom";
import { FiInstagram } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import "./Header.css";

export default function Header({ isPage = false }) {
  
  

  
  return (
    
    <header className={`header `}>
      <Navbar variant="light" expand="lg" className="pt-md-5">
        <div className="container nav-contain">
          <Navbar.Brand href="/" title="Rida Ali" className="title-rida ">
           
            <img src={Images.logo} className=" img-fluid  rida-profile d-md-none" alt="Rida Ali Profile" />
          </Navbar.Brand>
          <Navbar.Toggle style={{height: 'fit-content', width: 'fit-content'}} aria-controls="basic-navbar-nav" >
            <GiHamburgerMenu  className={`hamburger ${isPage && 'text-black' }`}/>
            <ImCross
              
              className={`cross ${isPage && 'text-black' }`}
              style={{ height: "1rem" }}
            />
          </Navbar.Toggle>
          <Navbar.Collapse  id="basic-navbar-nav">
            <Nav className={`justify-content-center flex-grow-1   d-flex `}>
            <Link
                to="/"
                className={` ms-0 ms-lg-4 mt-4 my-lg-auto nav-custom-edits ${isPage && 'text-black' }`}
                title="About me"
              >
                <>About me</>
              </Link>
              <Link
                to="/resume"
                className={` ms-0 ms-lg-4 mt-4 my-lg-auto nav-custom-edits ${isPage && 'text-black' }`}
                title="Resume"
              >
                <>Resume</>
              </Link>

              <Link
                to="/"
                className={`ms-0 ms-lg-4 mt-4 my-lg-auto  nav-custom-edits ${isPage && 'text-black' }`}
                title="Projects"
              >
                <>Projects </>
              </Link>
              <Link
                to="/"
                className={`ms-0 ms-lg-4 mt-4 my-lg-auto  nav-custom-edits ${isPage && 'text-black' }`}
                title="instagram"
              >
               <FiInstagram />
              </Link>
            </Nav>
          </Navbar.Collapse>
        </div>

      
      </Navbar>
     
    </header>
  );
}