import React, { useState } from "react";
import Images from "../../constant/Images";
import "./Projects.css";
export default function Projects() {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="row mx-0 px-0 py-5 justify-content-center">
      <div className="col-lg-10 ">
        <h2 className="text-start about-title py-5">PROJECTS</h2>
        <p className="about-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
          vulputate tristique quam felis. Id phasellus dui orci vulputate
          consequat nulla proin. Id sit scelerisque neque, proin bibendum diam.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
          vulputate tristique quam felis. Id phasellus dui orci vulputate
          consequat nulla proin. Id sit scelerisque neque, proin bibendum diam.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
          vulputate tristique quam felis. Id phasellus dui orci vulputate
          consequat nulla proin.{" "}
        </p>
        <div className="row text-start ">
          <div className="col-lg-4">
          <div className="hover-card ">
            <img
              src={Images.Project1}
              className=" img-fluid  projects-1"
              alt="Rida Ali Profile"
            />
            <div className="hover-overlay">
                <h2 className="hover-text">villa furnish</h2>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
          <div className="hover-card ">
            <img
              src={Images.Project2}
              className=" img-fluid  projects-1"
              alt="Rida Ali Profile"
            />
            <div className="hover-overlay">
                <h2 className="hover-text">subway</h2>
              </div>
           
            </div>
           
          </div>
          <div className="col-lg-4">
          <div className="hover-card ">
            <img
              src={Images.Project3}
              className=" img-fluid  projects-1"
              alt="Rida Ali Profile"
            />
            <div className="hover-overlay">
                <h2 className="hover-text">PAVILLION</h2>
              </div>
           
            </div>
          </div>
        </div>
        <div className="row text-start py-4 ">
          <div className="col-lg-6 img-over ">
            <div className="hover-card ">
              <img
                src={Images.landscape}
                className=" img-fluid landscape-1  "
                alt="Rida Ali Profile"
              />
              <div className="hover-overlay">
                <h2 className="hover-text">SWAY BLUEPRINT</h2>
              </div>
            </div>
          </div>
          <div className="col-lg-6 img-over ">
            <div className="hover-card ">
              <img
                src={Images.landscape1}
                className=" img-fluid landscape-1  "
                alt="Rida Ali Profile"
              />
              <div className="hover-overlay">
                <h2 className="hover-text">villa   BLUEPRINT</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="row text-start ">
          <div className="col-lg-4">
            <img
              src={Images.Project1}
              className=" img-fluid  projects-1"
              alt="Rida Ali Profile"
            />
            <div className="text-project"> Furnishing & Interior</div>
            <div></div>
          </div>
          <div className="col-lg-4">
            <img
              src={Images.Project2}
              className=" img-fluid  projects-1"
              alt="Rida Ali Profile"
            />
            <div className="text-project"> Furnishing & Interior</div>
          </div>
          <div className="col-lg-4">
            <img
              src={Images.Project3}
              className=" img-fluid  projects-1"
              alt="Rida Ali Profile"
            />
            <div className="text-project"> Furnishing & Interior</div>
          </div>
        </div>
      </div>
    </div>
  );
}
