import React from 'react';
import { Header} from "../../common";
import { About,Resume,Projects} from "../../Pages";
import Images from "../../constant/Images";
import "./HomePage.css";

export default function HomePage() {
  return (
    <div >
      <div className='row mx-0 home'>
        <div className='col-12 col-md-5  pt-md-5 text-start ps-md-5 intro-content'>
        <img src={Images.Ridaali} className=" img-fluid  logo-rida ms-5 d-none d-md-block" alt="Rida Ali" />
        <div>
          <div className='main-text'>
            <div>
        <h1 className='ms-5 mt-5 pt-4 pb-4 intro-title'>Hi, I am Rida Ali</h1>
        <h2 className='ms-5 profession'>Interior</h2>
        <h2 className='ms-5 profession-architect'>Architect</h2>
        </div>
        </div>
        </div>
        </div>
        <div className='col-12 col-md-7 profile-content '>
          {/* <div className='content-more'> */}
          <Header/>
          {/* </div> */}
          <div className='d-flex img-portfolio'>
          <img src={Images.portfolio} className=" img-fluid  rida-profile" alt="Rida Ali Profile" />
          </div>
          

        </div>

      </div>
      <div>
       <About/>
       {/* <Resume/> */}
       <Projects/>
      </div>
     
     
    </div>
  )
}
