import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
    HomePage,
    Resume
    
  } from "../Pages";

export default function AppRouter() {
  return (

    <BrowserRouter basename="/" >
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/resume" element={<Resume />} />
        
      </Routes>
    </BrowserRouter>

  );

}